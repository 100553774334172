import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import menuImg from '../images/menu.jpg';

import {
  menu,
  category,
  header,
  headingJa,
  glyph,
  product,
  price,
  column,
} from '../css/Menu.module.css';

const categoryPropTypes = {
  category: PropTypes.shape({
    menuCategories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          menucategorydata: PropTypes.shape({
            name: PropTypes.string,
            nameJap: PropTypes.string,
            menuPosition: PropTypes.number,
            glyph: PropTypes.string,
          }),
        }),
      ),
    }),
    menuProducts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          menuproductdata: PropTypes.shape({
            name: PropTypes.string,
            price: PropTypes.number,
          }),
        }),
      ),
    }),
  }),
};

const Category = (props) => {
  const { category: c } = props;
  const categoryData = c.menuCategories.nodes[0].menucategorydata;

  return (
    <section key={categoryData.name} className={category}>
      <header className={header}>
        <h3>{categoryData.name}</h3>
        <h4 className={headingJa}>({categoryData.nameJap})</h4>
      </header>
      <p className={glyph}>{categoryData.glyph}</p>
      {map(c.menuProducts.nodes, (p) => (
        <section key={p.menuproductdata.name} className={product}>
          <span>{p.menuproductdata.name}</span>
          <span className={price}>{p.menuproductdata.price.toFixed(2)}</span>
        </section>
      ))}
    </section>
  );
};

Category.propTypes = categoryPropTypes;

const Menu = ({ data }) => {
  const { wpWebdata, allWpMenucategorytaxonomy } = data;
  const attributes = wpWebdata;

  const menuCategories = allWpMenucategorytaxonomy.nodes.map((cat) => {
    const sortedProducts = [...cat.menuProducts.nodes].sort((a, b) => (
      a.menuproductdata.price - b.menuproductdata.price
    ));
    return { ...cat, menuProducts: { nodes: sortedProducts } };
  });

  const leftCategories = filter(menuCategories, (c) => c.menuCategories.nodes[0].menucategorydata.menuPosition === 'left');
  const rightCategories = filter(menuCategories, (c) => c.menuCategories.nodes[0].menucategorydata.menuPosition === 'right');

  return (
    <Layout pageTitle="Menu" copyright={attributes.shibuya.copyright}>
      <Hero backgroundImage={menuImg} attributes={attributes} />
      <article className="article">
        <h1>Menu</h1>
        <section className={menu}>
          <section className={column}>
            {map(leftCategories, (c, i) => (
              <Category key={i} category={c} />
            ))}
          </section>
          <section className={column}>
            {map(rightCategories, (c, i) => (
              <Category key={i} category={c} />
            ))}
          </section>
        </section>
      </article>
    </Layout>
  );
};

export default Menu;

Menu.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
    allWpMenucategorytaxonomy: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          menuCategories: PropTypes.shape({
            nodes: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                menucategorydata: PropTypes.shape({
                  name: PropTypes.string,
                  nameJap: PropTypes.string,
                  menuPosition: PropTypes.number,
                  glyph: PropTypes.string,
                }),
              }),
            ),
          }),
          menuProducts: PropTypes.shape({
            nodes: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                menuproductdata: PropTypes.shape({
                  name: PropTypes.string,
                  price: PropTypes.number,
                }),
              }),
            ),
          }),
        }),
      ),
    }),
  }).isRequired,
};

export const query = graphql`
query($language: String!) {
  wpWebdata(title: { eq: $language }) {
    title
    shibuya {
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
      copyright
    }
  }
  allWpMenucategorytaxonomy(sort: {fields: menuCategories___nodes___date, order: ASC}) {
  nodes {
    menuCategories {
      nodes {
        menucategorydata {
            name
            nameJap
            menuPosition
            glyph
          }
        }
      }
    menuProducts {
      nodes {
        menuproductdata {
            name
            price
          }
        }
      }
    }
  }
}
`;
