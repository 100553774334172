import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import Header from './Header';
import { hero, cats } from './Hero.module.css';

const propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    title_home: PropTypes.string,
    title_about_us: PropTypes.string,
    title_menu: PropTypes.string,
    title_reserve: PropTypes.string,
    title_faq: PropTypes.string,
    title_contact: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
};

const Hero = (props) => {
  const { backgroundImage, attributes } = props;

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(209, 58, 54, 0.65), rgba(209, 58, 54, 0.65)), linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), linear-gradient(#737373, #737373), url(${backgroundImage})`,
  };

  return (
    <section className={hero} style={containerStyle}>
      <Header attributes={attributes} />
      <StaticImage src="../images/cats.png" alt="Shibuya" placeholder="transparent" className={cats} />
    </section>
  );
};

Hero.propTypes = propTypes;

export default Hero;
